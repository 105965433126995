<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-row>
            <!-- garden name -->
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Garden Name" vid="garden_id" :rules="{required:false}">
                <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="garden_id">
                    <template v-slot:label>
                    {{ $t('teaGardenConfig.select_garden') }} <span class="text-danger">*</span>
                    </template>
                    <v-select
                        :disabled="isGardenAdmin"
                        :reduce="op => op.value"
                        :options="teaGardenGenInfoList"
                        label="text"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :placeholder="$t('globalTrans.select')"
                        plain
                        v-model="search.garden_id"
                        id="garden_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </v-select>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <ValidationProvider name="PF ID" vid="pf_id" :rules="{required:false}">
                <b-form-group
                  slot-scope="{ valid, errors }"
                  label-for="pf_id">
                  <template v-slot:label>
                    {{ $t('teaGardenConfig.pf_id') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="pf_id"
                    v-model="search.pf_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <b-button @click="searchWorker()" variant="primary" class="mt-3 btn-sm">{{ $t('globalTrans.search') }}</b-button>
            <b-form-group
                  slot-scope="{ valid, errors }"
                  label-for="pf_id">
                  <template v-slot:label>
                    {{ $t('teaGardenConfig.pf_id') }}
                  </template>
                  <b-form-input
                    id="pf_id"
                    v-model="search.pf_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
            </b-col>
          </b-row>
          <b-col sm="6">
            <ValidationProvider name="Date" vid="date" rules="required">
              <b-form-group
                class="row"
                label-for="date"
                slot-scope="{ valid, errors }">
                <template v-slot:label>
                  {{ $t('globalTrans.date') }}  <span class="text-danger">*</span>
                </template>
                <date-picker
                  id="date"
                  v-model="data.date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :class="errors[0] ? 'is-invalid' : ''"
                  :locale="currentLocale"
                >
                </date-picker>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-overlay :show="searchData">
            <b-col sm="12">
                <h6 class="font-weight-bold mb-2">{{ $t('teaGarden.worker_info') }}</h6>
                <table class="table table-sm table-bordered section-tree-view-table">
                    <thead>
                        <tr>
                            <th>{{ $t('teaGardenConfig.worker_name') }}</th>
                            <th>{{ $t('teaGardenConfig.pf_id') }}</th>
                            <th>{{ $t('teaGardenConfig.designation') }}</th>
                            <th>{{ $t('teaGarden.amount') }} <span class="text-danger">*</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="data.worker.garden_id">
                          <td><span v-if="data.worker.worker_name_en">{{ currentLocale === 'en' ? data.worker.worker_name_en : data.worker.worker_name_bn }}</span></td>
                          <td><span v-if="data.worker.pf_id">{{ EngBangNum(data.worker.pf_id) }}</span></td>
                          <td><span v-if="data.worker.designation_id">{{ getDesignationName(data.worker.designation_id) }}</span></td>
                          <td style="width:30%">
                              <ValidationProvider name="Amount" vid="amount" rules="required">
                                <b-form-group
                                  slot-scope="{ valid, errors }"
                                  label-for="amount">
                                  <b-form-input
                                    id="amount"
                                    v-model="data.amount"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                          </td>
                        </tr>
                    </tbody>
                    <tfoot v-if="!data.worker.garden_id">
                        <tr>
                            <td class="text-center text-danger" colspan="4"><b>{{$t('globalTrans.no_data_found')}}</b></td>
                        </tr>
                    </tfoot>
                </table>
            </b-col>
         </b-overlay>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { advanceSalaryStore, advanceSalaryUpdate, searchWorkerInfoApi } from '../../../api/routes'
import { helpers } from '@/utils/helper-functions'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
  mixins: [teaGardenService],
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      isGardenAdmin: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      data: {
        worker_id: 0,
        garden_id: 0,
        date: new Date().toISOString().slice(0, 10),
        worker: {}
      },
      search: {
        pf_id: '',
        garden_id: ''
      },
      searchData: false
    }
  },
  created () {
    if (this.id) {
      const tmp = this.advanceSalaryList()
      this.data = tmp
    }
    if (this.isGardenAdminCheckGardenId()) {
        this.isGardenAdmin = true
        this.search.garden_id = this.isGardenAdminCheckGardenId()
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    teaGardenGenInfoList: function () {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    }
  },
  methods: {
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(teaGardenServiceBaseUrl, `${advanceSalaryUpdate}/${this.id}`, this.data)
      } else {
        result = await RestApi.postData(teaGardenServiceBaseUrl, advanceSalaryStore, this.data)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    async searchWorker () {
      this.searchData = true
      let result = null
      result = await RestApi.postData(teaGardenServiceBaseUrl, searchWorkerInfoApi, this.search)
      this.searchData = false
      if (result.success) {
        this.data.worker = result.data
        this.data.garden_id = result.data.garden_id
        this.data.worker_id = result.data.id
      } else {
        this.data.worker = {}
      }
    },
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    advanceSalaryList () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
          return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
          return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getDesignationName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterDesignationList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    }
  }
}
</script>
